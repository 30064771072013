<template>
  <div>
    <div id="payslip">
      <div id="scope1">
        <div class="">
          <div>
            <img class="logo" :src="entete.logo_url" />
          </div>
        </div>
        <div class="text-right">
          <div id="title1">FICHE DE PAIE</div>
          {{ toDate(toPrint.date) }}
        </div>
      </div>
      <div id="scope1">
        <div class="">
          <div class="title font-weight-bold">{{ toPrint.user_name }}</div>
          <div class="value font-weight-bold">Téléphone : {{ toPrint.user_phone }}</div>
          <div class="value font-weight-bold">Poste : {{ toPrint.user_poste }}</div>
          <div class="value font-weight-bold">Date d'embauche : {{ toPrint.hiring_date }}</div>
        </div>
      </div>
      <div id="scope">
        <div class="scope-entry">
          <div class="title font-weight-bold">DETAILS DE PAIEMENT</div>
        </div>
      </div>

      <div class="content">
        <div class="right-panel">
          <div class="details">
            <div class="basic-pay">
              <div class="entry">
                <div class="label">Salaire de base</div>
                <div class="detail"></div>
                <div class="rate"></div>
                <div class="amount">
                  {{ $thousandSeparator(toPrint.user_salary) }}
                </div>
              </div>
            </div>
            <div class="basic-pay">
              <div class="entry">
                <div class="label">Prime</div>
                <div class="detail"></div>
                <div class="rate"></div>
                <div class="amount">
                  {{
                    toPrint.current_prime
                      ? $thousandSeparator(toPrint.current_prime)
                      : 0
                  }}
                </div>
              </div>
            </div>

            <div class="taxable_commission"></div>

            <div class="nti">
              <div class="entry">
                <div class="label">SALAIRE BRUT</div>
                <div class="detail"></div>
                <div class="rate"></div>
                <div class="amount">{{ $thousandSeparator(totalPaye) }}</div>
              </div>
            </div>
            <div class="withholding_tax">
              <div class="entry">
                <div class="label">Montant remboursé</div>
                <div class="detail"></div>
                <div class="rate"></div>
                <div class="amount">
                  {{
                    toPrint.last_debt_paid
                      ? "(" + $thousandSeparator(toPrint.last_debt_paid) + ")"
                      : 0
                  }}
                </div>
              </div>
            </div>

            <div class="net_pay">
              <div class="entry">
                <div class="label">NET à PAYER</div>
                <div class="detail"></div>
                <div class="rate"></div>
                <div class="amount">{{ $thousandSeparator(nap) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="scope">
        <div class="scope-entry">
          <div class="title font-weight-bold">CREDITS</div>
        </div>
      </div>

      <div class="content">
        <div class="right-panel">
          <div class="details">
            <!-- <div class="basic-pay">
              <div class="entry">
                <div class="label">Crédit principal</div>
                <div class="detail"></div>
                <div class="rate"></div>
                <div class="amount">{{ toPrint.last_debt_paid ? $thousandSeparator(toPrint.last_debt_paid) :0 }}</div>
              </div>
            </div>
            <div class="basic-pay">
              <div class="entry">
                <div class="label">Somme remboursée</div>
                <div class="detail"></div>
                <div class="rate"></div>
                <div class="amount">{{ toPrint.debt_paid ? $thousandSeparator(toPrint.debt_paid) : 0 }}</div>
              </div>
            </div> -->

            <div class="taxable_commission"></div>

            <div class="nti">
              <div class="entry">
                <div class="label">reste à payer</div>
                <div class="detail"></div>
                <div class="rate"></div>
                <div class="amount">
                  {{
                    toPrint.rap
                      ? $thousandSeparator(toPrint.rap)
                      : $thousandSeparator(rap)
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div id="scope2">
        <div>
          <div id="">LE TRAVAILLEUR</div>
          <br /><br />
          <!-- {{ toPrint.user_name }} -->
        </div>
        <div>
          <div>LE RESPONSABLE</div>
          <br /><br />
          <!-- <div>{{ entete.responsable }}</div> -->
        </div>
      </div>
      <div class="footer">
        <div
          class="text-right"
          style="bottom: 0; font-size: 10px; opacity: 0.5"
        >
          Edité par FlashCar
        </div>
        <hr />
        <div class="d-flex text-center">
          {{ entete.telephone }} - RCCM {{ entete.rccm }} - IFU
          {{ entete.ifu }}
        </div>
        <div class="d-flex justify-center">
          {{ entete.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    toPrint: {},
    entete: {},
  }),

  computed: {
    totalPaye() {
      return (
        parseInt(this.toPrint.user_salary) +
        parseInt(this.toPrint.current_prime)
      );
    },

    nap() {
      return (
        parseInt(this.totalPaye) -
        parseInt(
          this.toPrint.last_debt_paid ? this.toPrint.last_debt_paid : "0"
        )
      );
    },

    rap() {
      // return parseInt(this.toPrint.last_debt_paid ? this.toPrint.last_debt_paid : '0') - parseInt(this.toPrint.debt_paid ? this.toPrint.debt_paid : '0')
      return parseInt(this.toPrint.debt ? this.toPrint.debt : "0");
    },
  },

  mounted() {
    let data = this.$router.currentRoute.query;
    this.toPrint = data;
    // console.log('toPrint', this.toPrint)

    this.getEntete().then(() => {
      setTimeout(() => {
        window.print();
      }, 2000);
    });
  },

  methods: {
    telecharger() {
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      window.onafterprint = window.close();
    },

    async getEntete() {
      await this.$http.get("/societes").then((response) => {
        this.entete = response.data.data[0];
      });
    },

    toDate(dateStr) {
      if (dateStr) {
        dateStr = dateStr.replace(/\//g, "-");
        var parts = dateStr.split("-");
        return this.datetoString(parts[1]) + " " + parts[0];
      }
    },

    datetoString(number) {
      // let numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
      let mois = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      return mois[parseInt(number - 1)];
    },

    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = date.getMonth();
        let jour = date.getDate();
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },

    numToWords(n) {
        let a = [
          '', 'un', 'deux', 'trois', 'quatre',
          'cinq', 'six', 'sept', 'huit', 'neuf',
          'dix', 'onze', 'douze', 'treize', 'quartorze',
          'quinze', 'seize', 'dix-sept', 'dix-huit', 'dix-neuf'
        ];
        let b = [
          '', '', 'vingt', 'trente', 'quarante',
          'cinquante', 'soixante', 'soixante-dix', 'quatre-vingt', 'quatre-vingt-dix'
        ];
        let g = [
          '', 'mille', 'million', 'milliard', 'trilliard', 'quadrilliard',
          'quintilliard', 'sextilliard', 'septilliard', 'octilliard', 'nonilliard'
        ];

        const arr = x => Array.from(x);
        const num = x => Number(x) || 0;
        const str = x => String(x);
        const isEmpty = xs => xs.length === 0;
        const take = n => xs => xs.slice(0,n);
        const drop = n => xs => xs.slice(n);
        const reverse = xs => xs.slice(0).reverse();
        const comp = f => g => x => f (g (x));
        const not = x => !x;
        const chunk = n => xs =>
          isEmpty(xs) ? [] : [take(n)(xs), ...chunk (n) (drop (n) (xs))];
          
        // this part is really nasty still
        // it might edit this again later to show how Monoids could fix this up
        let makeGroup = ([ones,tens,huns]) => {
          return [
            num(huns) === 0 ? '' : num(huns) === 1 ? 'cent ': a[huns] + ' cent ',
            num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
            a[tens+ones] || a[ones]
          ].join('');
        };

        // "thousands" constructor; no real good names for this, i guess
        let thousand = (group, i) => group === 'un' && g[i] === 'mille' ? g[i]: `${group} ${g[i]}`;
        
        // execute !
        if (typeof n === 'number') return this.numToWords(String(n));
        if (n === '0')             return 'zéro';
        return (comp (chunk(3)) (reverse) (arr(n)))
          .map(makeGroup)
          .map(thousand)
          .filter(comp(not)(isEmpty))
          .reverse()
          .join(' ')
          .trim();
    },

    convertNumberToWords(n, custom_join_character) {
      //     n = 1580000;
      var string = n.toString(),
        units,
        tens,
        scales,
        start,
        end,
        chunks,
        chunksLen,
        chunk,
        ints,
        i,
        word,
        words;

      var and = custom_join_character || "et";

      /* Is number zero? */
      if (parseInt(string) === 0) {
        return "zero";
      }

      /* Array of units as words */
      units = [
        "",
        "un",
        "deux",
        "trois",
        "quatre",
        "cinq",
        "six",
        "sept",
        "huit",
        "neuf",
        "dix",
        "onze",
        "douze",
        "treize",
        "quatorze",
        "quinze",
        "seize",
        "dix-sept",
        "dix-huit",
        "dix-neuf",
      ];

      /* Array of tens as words */
      tens = [
        "",
        "",
        "vingt",
        "trente",
        "quarante",
        "cinquante",
        "soixante",
        "soixante-dix",
        "quatre-vingt",
        "quatre-vingt-dix",
      ];

      /* Array of scales as words */
      scales = [
        "",
        "mille",
        "million",
        "milliard",
        "trillion",
        "quadrillion",
        "quintillion",
        "sextillion",
        "septillion",
        "octillion",
        "nonillion",
        "decillion",
        "undecillion",
        "duodecillion",
        "tredecillion",
        "quatttuor-decillion",
        "quindecillion",
        "sexdecillion",
        "septen-decillion",
        "octodecillion",
        "novemdecillion",
        "vigintillion",
        "centillion",
      ];

      /* Split user arguemnt into 3 digit chunks from right to left */
      start = string.length;
      chunks = [];
      while (start > 0) {
        end = start;
        chunks.push(string.slice((start = Math.max(0, start - 3)), end));
      }

      /* Check if function has enough scale words to be able to stringify the user argument */
      chunksLen = chunks.length;
      if (chunksLen > scales.length) {
        return "";
      }

      /* Stringify each integer in each chunk */
      words = [];
      for (i = 0; i < chunksLen; i++) {
        chunk = parseInt(chunks[i]);

        if (chunk) {
          /* Split chunk into array of individual integers */
          ints = chunks[i].split("").reverse().map(parseFloat);

          /* If tens integer is 1, i.e. 10, then add 10 to units integer */
          if (ints[1] === 1) {
            ints[0] += 10;
          }

          /* Add scale word if chunk is not zero and array item exists */
          if ((word = scales[i])) {
            words.push(word);
          }

          /* Add unit word if array item exists */
          if ((word = units[ints[0]])) {
            words.push(word);
          }

          /* Add tens word if array item exists */
          if ((word = tens[ints[1]])) {
            words.push(word);
          }

          /* Add 'and' string after units or tens integer if: */
          if (ints[0] || ints[1]) {
            /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
            if (ints[2] || (!i && chunksLen)) {
              words.push(and);
            }
          }

          /* Add hundreds word if array item exists */
          if ((word = units[ints[2]])) {
            words.push(word + " cent");
          }
        }
      }

      return words.reverse().join(" ");
    },
  },
};
</script>

<style lang="scss" scoped>
@page {
    size: landscape;
}


body {
  background: #f0f0f0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 100%;
}

html[dir="ltr"] .content {
  margin-left: 0px;
}

.footer {
  margin-top: 10px !important;
}

#payslip {
  background: #fff;
  padding: 20px 40px;
}

.logo {
  // width: 150px !important;
  width: 40%;
  max-width: 150px;
  height: auto;
}

#title {
  margin-bottom: 20px;
  font-size: 38px;
  font-weight: 600;
}

#title1 {
  // margin-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
}

#scope {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 7px 0 4px 0;
  display: flex;
  justify-content: space-around;

  margin-top: 50px;
}

#scope1 {
  border-top: 1px solid #ccc;
  padding: 7px 0 4px 0;
  display: flex;
  justify-content: space-between;
}

#scope2 {
  padding: 7px 0 4px 0;
  display: flex;
  justify-content: space-between;
}

#scope > .scope-entry {
  text-align: center;
}

#scope > .scope-entry > .value {
  font-size: 14px;
  font-weight: 700;
}

.content {
  display: flex;
  border-bottom: 1px solid #ccc;
  text-transform: uppercase;
}

.content .left-panel {
  border-right: 1px solid #ccc;
  min-width: 350px;
  padding: 20px 16px 0 0;
}

.content .right-panel {
  width: 100%;
  // padding: 10px 0  0 16px;
}

.details .entry,
.contributions .entry,
.ytd .entry {
  display: flex;
  justify-content: space-between;
}

.details .entry .value,
.contributions .entry .value,
.ytd .entry .value {
  font-weight: 700;
  max-width: 130px;
  text-align: right;
}

.gross .entry .value {
  font-weight: 700;
  text-align: right;
  font-size: 16px;
}

.contributions .title,
.ytd .title,
.gross .title {
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
  margin-bottom: 6px;
}

.content .right-panel .details {
  width: 100%;
}

.content .right-panel .details .entry {
  display: flex;
  padding: 0 10px;
  margin: 6px 0;
}

.content .right-panel .details .label {
  font-weight: 700;
  width: 400px;
}

.content .right-panel .details .detail {
  font-weight: 600;
  width: 130px;
}

.content .right-panel .details .rate {
  font-weight: 400;
  width: 80px;
  font-style: italic;
  letter-spacing: 1px;
}

.content .right-panel .details .amount {
  text-align: right;
  width: 90px;
}

.content .right-panel .details .net_pay div,
.content .right-panel .details .nti div {
  font-weight: 600;
  font-size: 15px;
}

.content .right-panel .details .net_pay,
.content .right-panel .details .nti {
  padding: 3px 0 2px 0;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.04);
}
</style>
